body {
    margin: 0;
    font-family: 'Alegreya', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh !important;
    padding-bottom: env(safe-area-inset-bottom);
    overflow-x: hidden;
    max-width: 100vw;
    background-image: url("./img/background.jpg");
    
}

body > #root {
    backdrop-filter: blur(8px);
}

.root-container {
    background-image: url("./img/background.jpg");
    background-size: cover;
    min-height: 100vh;
    margin: auto;
    max-width: 1280px;
}

header {
    text-align: center;
    color: white;
}

.langPickerWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0 0;
}

.logo {
    margin-bottom: -10px;
    max-width: 80%;
}

h3 {
    margin: 0;
}

main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

main > ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    gap: 20px;
}

main > ul > li {
    display: block;
}

.social-media > li {
    width: 40px;
}

.social-icons > svg {
    color: inherit;
    stroke: white;
    fill: white;
}

main > .links {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 10px;
    max-width: 500px;
    overflow-x: hidden;
}

main > .links a {
    background-color: #759883;
    display: block;
    padding: 15px;
    border-radius: 40px;
    color: inherit;
    text-decoration: none;
    text-align: center;
    font-size: 1.2em;
    transition: background-color 0.2s, color 0.2s;
}

main > .links a:hover, main > .links a:active {
    background-color: white;
    color: #759883
}

main > .links a.LinkItem {
    position: relative;
    padding: 15px 55px;
}

.LinkItem .badge {
    width: 25px;
    height: 25px;
    text-align: center;
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 100%;
    display: none;
}

.LinkItem .badge:not(.custom) {
    background: #8B0E1B;
}

.LinkItem .badge.display,
.LinkItem .badge.custom {
    display: block;
}

.menu-modal li {
    font-size: 1.5em;
}

.OfferBanner {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding: 20px;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: white;
    box-shadow: 1px 2px 10px rgba(0,0,0,0.5);
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1000;
}

.OfferBanner .bg-div {
    background: url("./img/bg_valentin.jpg");
    background-size: cover;
    opacity: 0.7;
    z-index: -1;
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

@media (max-width: 480px) {
    .OfferBanner {
        flex-direction: column;
        gap: 5px;
        padding: 10px;
    }
}

.OfferBanner > span {
    font-size: 1.4em;
}

#nextui-modal > div[class*="fullScreen-true"] {
    top: 0;
    bottom: 0;
    bottom: env(safe-area-inset-bottom);
}
#nextui-modal > div[class*="fullScreen-true"] > .nextui-backdrop-content {
    height: 100%;
}

